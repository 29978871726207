import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface Props {
  image: string;
  logo: string;
  convenienceList: { [key: string]: string };
}

const city = process.env.REACT_APP_CITY;

export function VoucherSample({ image, logo, convenienceList }: Props) {
  const [conveniencesToShow, setConveniencesToShow] = useState<string[] | null>(
    null
  );

  const { watch } = useFormContext();

  const [
    name,
    establishmentRules,
    reward,
    onPurchaseOf,
    holiday,
    commemorativeDate,
    takeaway,
  ] = watch([
    "establishment.name",
    "voucher.establishmentRules",
    "voucher.reward",
    "voucher.onPurchaseOf",
    "holiday",
    "commemorativeDate",
    "takeaway",
  ]);

  const [street, number, complement, district] = watch([
    "address[0].street",
    "address[0].number",
    "address[0].complement",
    "address[0].district",
  ]);

  const conveniences = useWatch({ name: "address[0].conveniences" });
  const hourList = useWatch({ name: "voucher.usableTimes" });

  useEffect(() => {
    const parsedList = Object.entries(conveniences ?? {})
      .filter(([_, value]) => !!value)
      .map(([key, _]) => convenienceList[key]);

    if (takeaway?.value) {
      const takeawayList = takeaway?.value
        ?.split(",")
        ?.map((key: string) => convenienceList[key]);

      setConveniencesToShow(parsedList.concat(takeawayList));
    } else {
      setConveniencesToShow(parsedList);
    }
  }, [conveniences, takeaway]);

  function replaceWithBr(text: string) {
    return text?.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <div className="voucher col-md-10 col-xl-4 d-flex flex-wrap align-content-start">
      <h4>EXEMPLO DO VOUCHER</h4>
      <div
        id="voucher"
        className="col-12 d-flex flex-wrap p-0 align-content-start"
      >
        <div className="col-12 logo text-center">
          <img src={`assets/img/${city}/topo-voucher.png`} className="img-bg" />
        </div>
        <div className="nome-estabelecimento">
          {name || "NOME DO ESTABELECIMENTO"}
        </div>
        <div className="foto-voucher relative">
          <img
            id="fotoTour"
            src={!!image ? image : `assets/img/foto-exemplo.png`}
            className="img-bg"
          />
          <div className="logo-voucher">
            <img
              id="logoTour"
              src={!!logo ? logo : `assets/img/${city}/logo-exemplo.png`}
              className="img-bg"
            />
          </div>
        </div>
        <div className="beneficio-voucher col-12">
          Na compra de{" "}
          <strong>
            <span className="compra-voucher">{onPurchaseOf}</span>
          </strong>{" "}
          <span className="ganhe-voucher">{reward?.label}</span>
        </div>
        <div className="content-voucher col-12">
          <img src="assets/img/desconto-voucher.png" className="img-bg" />
          {hourList?.map((hour: any, index: number) => {
            return (
              <div id="voucher-horario" key={index}>
                <div className="dias-voucher col-12 d-flex flex-wrap justify-content-center">
                  <div className="horario-dia d-flex flex-wrap align-items-center col-12 justify-content-center">
                    <img src="assets/img/icon-sol.png" className="img-fluid" />
                    &nbsp;
                    <div className="inicio-dia horario">
                      {!!hour?.start ? hour.start : "00:00"}
                    </div>
                    &nbsp;-&nbsp;
                    <img src="assets/img/icon-lua.png" className="img-fluid" />
                    &nbsp;
                    <div className="fim-noite horario">
                      {!!hour?.end ? hour.end : "00:00"}
                    </div>
                  </div>
                  <div className="semana-dia semana-voucher d-flex flex-wrap col-10 col-lg-8 justify-content-between">
                    <div
                      className={`segunda-dia dia-semana ${
                        hour.days.includes("monday") && "dia-aceito"
                      }`}
                    >
                      S
                    </div>
                    <div
                      className={`terca-dia dia-semana ${
                        hour.days.includes("tuesday") && "dia-aceito"
                      }`}
                    >
                      T
                    </div>
                    <div
                      className={`quarta-dia dia-semana ${
                        hour.days.includes("wednesday") && "dia-aceito"
                      }`}
                    >
                      Q
                    </div>
                    <div
                      className={`quinta-dia dia-semana ${
                        hour.days.includes("thursday") && "dia-aceito"
                      }`}
                    >
                      Q
                    </div>
                    <div
                      className={`sexta-dia dia-semana ${
                        hour.days.includes("friday") && "dia-aceito"
                      }`}
                    >
                      S
                    </div>
                    <div
                      className={`sabado-dia dia-semana ${
                        hour.days.includes("saturday") && "dia-aceito"
                      }`}
                    >
                      S
                    </div>
                    <div
                      className={`domingo-dia dia-semana ${
                        hour.days.includes("sunday") && "dia-aceito"
                      }`}
                    >
                      D
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="datas-voucher col-12 d-flex flex-wrap justify-content-center">
            {holiday?.value === "true" ? (
              <div className="aceitaferiado info-feriado">
                <img src="assets/img/icon-check.png" className="img-fluid" />
                &nbsp;Feriados
              </div>
            ) : (
              <div className="naoaceitaferiado info-feriado">
                <img src="assets/img/icon-x.png" className="img-fluid" />
                &nbsp;Feriados
              </div>
            )}

            {commemorativeDate?.value === "true" ? (
              <div className="aceitadata info-data">
                <img src="assets/img/icon-check.png" className="img-fluid" />
                &nbsp;Datas Comemorativas
              </div>
            ) : (
              <div className="naoaceitadata info-data">
                <img src="assets/img/icon-x.png" className="img-fluid" />
                &nbsp;Datas Comemorativas
              </div>
            )}
          </div>
          <div className="col-12 regras-voucher d-flex flex-wrap align-items-stretch row">
            <div className="col-2 icone d-flex flex-wrap justify-content-center align-items-center">
              <img src="assets/img/icon-regras.png" className="img-fluid" />
            </div>
            <div className="col-10 descricao-regra">
              {replaceWithBr(establishmentRules)}
            </div>
          </div>
          <div className="caracteristicas-voucher d-flex flex-wrap justify-content-center">
            {conveniencesToShow?.map((item, index) => (
              <div key={index} className="caracteristica col-auto aceitatakea">
                {item}
              </div>
            ))}
          </div>
          <img
            src={`assets/img/${city}/contatos-voucher.png`}
            className="img-bg"
          />
        </div>
        <div className="content-voucher col-12 d-flex flex-wrap justify-content-center">
          <div className="col-auto endereco-voucher text-center">{street}</div>
          ,&nbsp;Nº&nbsp;
          <div className="col-auto numero-voucher text-center">{number}</div>
          &nbsp;-&nbsp;
          <div className="col-auto complemento-voucher text-center">
            {complement}
          </div>
          &nbsp;
          <div className="col-auto bairro-voucher text-center">{district}</div>
          <br />
          <br />
          <img
            src={`assets/img/${city}/voucher-footer.png`}
            className="img-bg"
          />
        </div>
      </div>
    </div>
  );
}
